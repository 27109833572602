/* Light Mode Styles */
/* Light Mode Styles */
/* Light Mode Styles */
/* Light Mode Styles */
.date-range-picker.light {
  background-color: #fff;
  color: #000;
}

.date-range-picker.light .rdrCalendar {
  background-color: #f2f2f2;
}

.date-range-picker.light .rdrDay,
.date-range-picker.light .rdrDateDisplay,
.date-range-picker.light .rdrDefinedRangesWrapper,
.date-range-picker.light .rdrStaticRanges,
.date-range-picker.light .rdrMonthAndYearPickers,
.date-range-picker.light .rdrWeekDays,
.date-range-picker.light .rdrNextPrevButton {
  color: #000;
}

.date-range-picker.light .rdrDateDisplayItemActive,
.date-range-picker.light .rdrSelected,
.date-range-picker.light .rdrInRange,
.date-range-picker.light .rdrStartEdge,
.date-range-picker.light .rdrEndEdge {
  background-color: #007bff;
  color: #fff;
}

.date-range-picker.light .rdrDayHovered {
  background-color: #e6e6e6;
}

.date-range-picker.light .rdrNextPrevButton i {
  color: #000;
}

/* Dark Mode Styles */
.date-range-picker.dark {
  background-color: #333;
  color: #fff;
}

.date-range-picker.dark .rdrCalendar {
  background-color: #444;
}

.date-range-picker.dark .rdrDay,
.date-range-picker.dark .rdrDateDisplay,
.date-range-picker.dark .rdrDefinedRangesWrapper,
.date-range-picker.dark .rdrStaticRanges,
.date-range-picker.dark .rdrMonthAndYearPickers,
.date-range-picker.dark .rdrWeekDays,
.date-range-picker.dark .rdrNextPrevButton {
  color: #fff;
}

.date-range-picker.dark .rdrDateDisplay,
.date-range-picker.dark .rdrSelected,
.date-range-picker.dark .rdrInRange,
.date-range-picker.dark .rdrStartEdge,
.date-range-picker.dark .rdrEndEdge {
  background-color: #007bff;
  color: #fff;
}

.date-range-picker.dark .rdrDayHovered {
  background-color: #888;
}

.date-range-picker.dark .rdrNextPrevButton i {
  color: #fff;
}

.TMMember-img {
  height: 70px;
}

.TMCard-h {
  height: 140px !important;
}

.popup-styles {
  width: 700px !important;
  height: 350px !important;
  border-radius: 14px;
  padding: 20px;
}
