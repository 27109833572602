.btn-ripple {
  display: inline-block;
  position: relative;
  overflow: hidden;
  transition: all ease-in-out 0.1s;
}
.btn-ripple::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 25%;
  height: 100%;
  width: 50%;
  background-color: #fff;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  transition: all ease-in-out 1s;
  transform: scale(5, 5);
}

.btn-ripple:active::after {
  padding: 0;
  margin: 0;
  opacity: 0.2;
  transition: 0s;
  transform: scale(0, 0);
}

.TMMember-img {
  height: 120px;
}

.TMCard-h {
  height: 140px !important;
}

.popup-styles {
  width: 700px !important;
  height: 350px !important;
  border-radius: 14px;
  padding: 20px;
}

.bg-custom-color {
  background-color: rgba(var(--bs-primary-rgb), 0.5) !important;
}

.bg-custom-success {
  background-color: rgba(var(--bs-success-rgb), 0.5) !important;
}

.bg-custom-info {
  background-color: rgba(var(--bs-info-rgb), 0.5) !important;
}

.bg-custom-danger {
  background-color: rgba(var(--bs-dark-rgb), 0.5) !important;
}

.designation-btn {
  min-width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 5px;
}

.icon-dotted-border {
  border-style: dotted;
}
