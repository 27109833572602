.carousel-custom {
  color: white;
  margin: 16px;
}

.icon {
  position: absolute;
  right: 10px; /* Adjust the right position as needed */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer; /* Add a pointer cursor to indicate interactivity */
}

.form-control-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.eye-icon {
  position: absolute;
  right: 0px; /* Adjust as needed */
  cursor: pointer;
}

.linear-bg {
  background-image: linear-gradient(to right, #e1f5fe, #ffffff);
}
