.rdrDateDisplayWrapper {
  background: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity));
}

.date-range-picker {
  background-color: var(--bs-body-bg) !important;
  color: var(--bs-primary) !important;
}

.date-range-picker.light .rdrCalendar {
  background-color: #f2f2f2;
}
.rdrDateDisplayItemActive input {
  color: white;
}

.date-range-picker.light .rdrDay,
.date-range-picker.light .rdrDateDisplay,
.date-range-picker.light .rdrDefinedRangesWrapper,
.date-range-picker.light .rdrStaticRanges,
.date-range-picker.light .rdrMonthAndYearPickers,
.date-range-picker.light .rdrWeekDays,
.date-range-picker.light .rdrNextPrevButton {
  color: #000;
}

.date-range-picker.light .rdrDateDisplayItemActive,
.date-range-picker.light .rdrSelected,
.date-range-picker.light .rdrInRange,
.date-range-picker.light .rdrStartEdge,
.date-range-picker.light .rdrEndEdge {
  background-color: #007bff;
  color: #fff;
}

.date-range-picker.light .rdrDayHovered {
  background-color: #e6e6e6;
}

.date-range-picker.light .rdrNextPrevButton i {
  color: #000;
}

.rdrMonthPicker {
  color: #fff;
}

.date-range-picker.dark .rdrDateDisplayItemActive,
.date-range-picker.dark .rdrSelected,
.date-range-picker.dark .rdrInRange,
.date-range-picker.dark .rdrStartEdge,
.date-range-picker.dark .rdrEndEdge {
  background-color: #007bff;
  color: #fff;
}

.date-range-picker.dark .rdrDay,
.date-range-picker.dark .rdrDateDisplay,
.date-range-picker.dark .rdrDefinedRangesWrapper,
.date-range-picker.dark .rdrStaticRanges,
.date-range-picker.dark .rdrMonthAndYearPickers,
.date-range-picker.dark .rdrWeekDays,
.date-range-picker.dark .rdrNextPrevButton {
  color: #fff;
}

.date-range-picker.dark .rdrCalendar {
  color: #fff;
}

.date-range-picker.dark .rdrDay,
.date-range-picker.dark .rdrDateDisplay,
.date-range-picker.dark .rdrDefinedRangesWrapper,
.date-range-picker.dark .rdrStaticRanges,
.date-range-picker.dark .rdrMonthAndYearPickers,
.date-range-picker.dark .rdrWeekDays,
.date-range-picker.dark .rdrNextPrevButton {
  color: #fff;
}

.date-range-picker.dark .rdrDayNumber span {
  /* color: #1d2429; */
  color: white;
}

.date-range-picker.dark.rdrDateDisplayItemActive,
.date-range-picker.dark .rdrDateDisplay,
.date-range-picker.dark .rdrSelected,
.date-range-picker.dark .rdrInRange,
.date-range-picker.dark .rdrStartEdge,
.date-range-picker.dark .rdrEndEdge {
  /* background-color: #007bff; */
  color: #000;
}

.TMMember-img {
  height: 70px;
}

.TMCard-h {
  height: 140px !important;
}

.popup-styles {
  width: 700px !important;
  height: 350px !important;
  border-radius: 14px;
  padding: 20px;
}
